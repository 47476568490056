import React from "react";
import cn from "classnames";
import { Button } from "react-bootstrap";
import Link from "next/link";

import useAuth from "hooks/useAuth";
import NewspaperIcon from "../../../common/icons/NewspaperIcon";
import UsersIcon from "../../../common/icons/UsersIcon";
import CommentsIcon from "../../../common/icons/CommentsIcon";
import styles from "./styles.module.scss";

function Card({
  title,
  subTitle,
  Icon,
  link,
  flip,
  flipTitle,
  flipDescription,
  flipActionText,
}) {
  return (
    <div className={styles.card}>
      <div className={styles.itemImage}>
        <Icon />
        <h4>{title}</h4>
        <p>{subTitle}</p>
      </div>
      {flip && (
        <Link href={link}>
          <a className={cn("v-hidden", styles.flipBox)} href={link}>
            <div className={styles.flipBoxOverlay}>
              <h3 className={styles.flipBoxTitle}>{flipTitle}</h3>
              <div className={styles.flipBoxDescription}>{flipDescription}</div>
              <Link href={link}>
                <Button size="sm" className={styles.flipBoxBtn}>
                  {flipActionText}
                </Button>
              </Link>
            </div>
          </a>
        </Link>
      )}
    </div>
  );
}

export default function JoinVoteSubAdv() {
  const { user, authUrl } = useAuth();

  const CARDS_LIST = [
    {
      id: "subscribe",
      title: "Subscribe",
      subTitle: "Stay updated with Real America's Voice News",
      Icon: NewspaperIcon,
      link: "/subscribe",
      flip: true,
      flipTitle: "Receive Exclusive Updates!",
      flipDescription:
        "We're looking forward to sending you exclusive news special event updates.",
      flipActionText: "Sign Up",
    },
  ];

  user
    ? CARDS_LIST.unshift({
        id: "welcome",
        title: "Welcome back!",
        subTitle: "You're in our community!",
        Icon: UsersIcon,
        link: "/auth/logout",
        flip: true,
        flipTitle: "Logout",
        flipDescription: "",
        flipActionText: "Logout",
      })
    : CARDS_LIST.unshift({
        id: "join",
        title: "Join",
        subTitle: "Join Real America's Voice News community",
        Icon: CommentsIcon,
        link: authUrl,
        flip: true,
        flipTitle: "Join The Community!",
        flipDescription:
          "There's finally a platform for your news, your opinion, and your voice!",
        flipActionText: "Join Now",
      });

  return (
    <div className={styles.root}>
      {CARDS_LIST.map((item) => (
        <Card key={item.id} {...item} />
      ))}
    </div>
  );
}
