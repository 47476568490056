import React from 'react';
import styles from './styles.module.scss';

export default function HeaderDefault({ block }) {
  return (
    <h3 className={styles.root}>
      <span>{block.title}</span>
    </h3>
  );
}
