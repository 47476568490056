import React from 'react';
import styles from './styles.module.scss';

export default function Builder({ block }) {
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: block.data.style }} />
      <div
        className={[styles.root, block.data.id ? `widget-${block.data.id}` : ''].join(' ')}
        dangerouslySetInnerHTML={{ __html: block.data.content }}
      />
    </>
  );
}
