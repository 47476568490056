import React from "react";
import styles from "./styles.module.scss";
import SliderBlock from "./SliderBlock";
import HtmlBlock from "./HtmlBlock";
import BuilderBlock from "./BuilderBlock";
import ImageBlock from "./ImageBlock";
import FormBlock from "./FormBlock";
import HeaderDefault from "./components/HeaderDefault";
import { NewsBlock } from "./NewsBlock";
import { ApplicationsBlock } from "./ApplicationsBlock";
import AlbumsBlock from "./AlbumsBlock";
import { useDevicesCondition } from "../../../hooks/useDevicesCondition";
import { LiveFeedWidget } from "../content/LiveFeedWidget";
import { UGCBlock } from "./UGCBlock";
import { TagsBlock } from "./TagsBlock";
import { GoogleManagerWidget } from "../content/GoogleManagerWidget";
import { GoogleAdWidget } from '../content/GoogleAdWidget'
// import { WeatherBlock } from "./WeatherBlock";

const widgets = {
  customHtml: (item) => <HtmlBlock block={item} />,
  textSection: (item) => <HtmlBlock block={item} />,
  builder: (item) => <BuilderBlock block={item} />,
  image: (item) => <ImageBlock block={item} />,
  form: (item) => <FormBlock block={item} />,
  liveFeed: (item) => <LiveFeedWidget widget={item} />,
  googleAds: (item) => <GoogleAdWidget widget={item} />,
  googleManager: (item) => <GoogleManagerWidget widget={item} />,
  slider: (item) => <SliderBlock block={item} />,
  news: (item) => <NewsBlock block={item} />,
  social: (item) => <HtmlBlock block={item} />,
  apps: (item) => <ApplicationsBlock block={item} />,
  album: (item) => <AlbumsBlock block={item} />,
  ugc: (item) => <UGCBlock block={item} />,
  tags: (item) => <TagsBlock block={item} />,
  // weather: (item) => <WeatherBlock block={item} />,
};

export default function Sidebar({ items, top = false }) {
  return (
    <div className={styles.root}>
      {Array.isArray(items) &&
        items.map((item, index) => {
          const isHeader = !!item.title;
          const isSlider =
            item?.data?.displayMethod === "slider" || item?.type === "slider";

          return (
            <SideBarItem
              key={index}
              item={item}
              isHeader={isHeader}
              isSlider={isSlider}
              top={top}
            />
          );
        })}
    </div>
  );
}

function SideBarItem({ item, isHeader, isSlider, top }) {
  const isCondition = useDevicesCondition(item.devices);

  return item && isCondition ? (
    <div className={top ? styles.topWrap : styles.wrap}>
      {isHeader && !isSlider && <HeaderDefault block={item} />}

      {widgets?.[item.type]?.(item)}
    </div>
  ) : null;
}
