import React from "react";
import { useImage } from "../../../../hooks/useImage";
import Link from "next/link";

export default function ImageBlock({ block }) {
  const { href, src, alt, target } = useImage(block);

  return (
    <Link href={href}>
      <a href={href} target={target ? "_blank" : "_self"}>
        <img width="100%" height="auto" src={src} alt={alt} loading="lazy" />
      </a>
    </Link>
  );
}
