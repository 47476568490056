import React from "react";
import { Button } from "react-bootstrap";
import Link from "next/link";
import cn from "classnames";

import { APPLE_APP, ANDROID } from "../../../../config/constants";
import styles from "./styles.module.scss";
import ArrowRightIcon from "../../../common/icons/ArrowRightIcon";

const APPS_LIST = [
  {
    link: "https://channelstore.roku.com/en-gb/details/249723/america's-voice-news",
    appName: "roku",
  },
  {
    link: ANDROID,
    appName: "android",
  },
  {
    link: APPLE_APP,
    appName: "apple",
  },
  {
    link: APPLE_APP,
    appName: "apple-tv",
  },
  {
    link: "https://www.amazon.com/Performance-One-Media-Americas-Voice/dp/B07HPGGNPC/ref=sr_1_1?s=mobile-apps&amp;ie=UTF8&amp;qid=1538488296&amp;sr=1-1",
    appName: "fire-tv",
  },
];

function ListItem({ link, appName }) {
  return (
    <a
      className={styles.item}
      href={link}
      target="_blank"
      rel="noreferrer"
      aria-label={`Open ${appName} application`}
    >
      <div className={styles.flipper}>
        <div
          className={cn(styles.sprite, styles[`sprite-${appName}`])}
          style={{
            backgroundImage:
              'url("https://cdn.americasvoice.news/static/images/apps-sprite-new.png")',
          }}
        />
      </div>
    </a>
  );
}

export default function OurApps({ hiddeView, sidebar }) {
  return (
    <React.Fragment>
      {!hiddeView && (
        <h4 className={styles.title}>
          <Link href={"/app"}>Get our Apps</Link>

          <Link href="/app">
            <Button size="sm" rel="noreferrer" className={styles.button}>
              Download now&nbsp;
              <ArrowRightIcon width={12} />
            </Button>
          </Link>
        </h4>
      )}
      <div className={cn(styles.list, sidebar && styles.listSidebar)}>
        {APPS_LIST.map((item, idx) => (
          <ListItem key={idx} {...item} />
        ))}
      </div>
    </React.Fragment>
  );
}
