import React from "react";
import styles from "./styles.module.scss";
import Preview from "../Preview";
import PostMeta from "components/common/meta/PostMeta";
import stripLink from 'helpers/stripLink';
import Link from 'next/link';

export default function Item(
  {
    thumbnail,
    createdAt,
    title,
    author,
    link,
  }
) {
  return (
    <div className={styles.root}>
      { thumbnail !== '0' && <Preview {...{ link, thumbnail, title }} /> }
      <div className={styles.info}>
        <h3 className={styles.title}>
          <Link href={stripLink(link)}>
            <a href={stripLink(link)} title={title} dangerouslySetInnerHTML={{__html: title}} ></a>
          </Link>
        </h3>
        <PostMeta {...{ author, createdAt }} />
      </div>
    </div>
  )
}
