import { format, isToday, isYesterday, isDate, parseISO } from "date-fns";
import { monthConverter } from "./monthConverter";

export const prepareDateTime = (
  created_at,
  template = "MMMM d, yyyy, h:mm aa"
) => {
  try {
    return format(new Date(created_at), template);
  } catch (error) {
    console.log("prepareDateTime.js:9 error", error);
    return null;
  }
};

// this function prepares date and time from ET (not UTC),
// cause ET time is returned from P1ML-api in categories
// for preventing shifting assets in different time-zones
export const prepareETDateTime = (created_at = "") => {
  const tempDate = new Date(created_at);

  if (!isValidDate(tempDate) || typeof created_at !== "string") {
    return null;
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  try {
    const [date, time] = created_at?.slice(0, 16)?.split("T");
    const [year, month, day] = date?.split("-");
    const dateStr = `${monthConverter(month)} ${prepareReadableDayDate(
      day
    )}, ${year}, ${prepareReadableTimeDate(time)}`;
    return dateStr;
  } catch (error) {
    console.log("prepareDateTime.js:9 error", error);
    return null;
  }
};

export const prepareDate = (dateStr) => {
  try {
    const date = parseISO(dateStr);

    if (!isDate(date)) {
      console.log(`prepareDateTime.js:11 isDate(${date}) - is not a date`);
      return dateStr;
    }

    if (isToday(date)) {
      return "Today";
    }
    if (isYesterday(date)) {
      return "Yesterday";
    }

    return prepareDateTime(date, "EEEE, MMMM d");
  } catch (error) {
    console.log("prepareDateTime.js:32 error", error);
    return null;
  }
};

function prepareReadableDayDate(day = "") {
  if (day?.[0] === "0") {
    return day?.[1];
  }
  return day;
}

function prepareReadableTimeDate(time = "") {
  try {
    let [hours, minutes] = time?.split(":");
    const numHours = Number(hours);
    let isPM = numHours >= 12;

    const AMhours = numHours === 0 ? 12 : numHours;
    const PMhours = numHours > 12 ? numHours - 12 : numHours;

    return `${isPM ? PMhours : AMhours}:${minutes} ${isPM ? "PM" : "AM"}`;
  } catch (error) {
    console.log("prepareDateTime.js:83", error);
    return "";
  }
}
