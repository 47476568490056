import { SingleSlider } from '../components/SingleSlider';
import ListBlock from '../ListBlock';

export function NewsBlock({ block: { data, title } }) {
  switch (data.displayMethod) {
    case 'slider':
      return <SingleSlider data={data} title={title} />;
    case 'list':
      return <ListBlock block={data} />;
    default:
      return <></>;
  }
}
