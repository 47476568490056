import React from "react";
import styles from "./styles.module.scss";
import { Dot } from "pure-react-carousel";

export default function Indicators({ items }) {
  return (
    items.length > 1 && (
      <div className={styles.root}>
        {items.map((item, idx) => (
          <Dot className={styles.dot} key={idx} slide={idx}>
            <span>
              <i />
            </span>
          </Dot>
        ))}
      </div>
    )
  );
}
