import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

function NoItems({ items = "items", className }) {
  return <p className={cn(styles.noItems, className)}>No {items} found</p>;
}

export default NoItems;
