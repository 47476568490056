import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

// import UserCircleIcon from "../../icons/UserCircleIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import { prepareDateTime } from "../../../../helpers/prepareDateTime";

export default function PostMeta({
  createdAt,
  // author,
  className = "pb-3",
  // authorClassName,
  dateClassName,
  isRelated = false,
}) {
  // const authorComponent = useMemo(() => {
  //   return (
  //     author && (
  //       <div className={cn(authorClassName, "d-flex align-items-center")}>
  //         <UserCircleIcon color="currentColor" className="me-1" />
  //         <span className={styles.clamp}>{author}</span>
  //       </div>
  //     )
  //   );
  // }, [author]);

  const dateComponent = useMemo(() => {
    const dateTitle = prepareDateTime(createdAt);
    if (!createdAt || !dateTitle) {
      return null;
    }
    return (
      <div className={cn(dateClassName, "d-flex align-items-center")}>
        <CalendarIcon
          color="currentColor"
          className="me-1"
          width={isRelated ? 9 : 11}
        />
        <span
          className={cn(isRelated ? styles.small : "", styles.clamp)}
          title={new Date(createdAt).toString()}
        >
          {dateTitle}
        </span>
      </div>
    );
  }, [createdAt]);

  return (
    <div className={cn(styles.root, className)}>
      {/* {authorComponent} */}
      {dateComponent}
    </div>
  );
}
