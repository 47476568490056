import React, { useEffect } from "react";
import styles from "./styles.module.scss";

export default function Trailer({ trailer, onStart, onLoaded }) {
  useEffect(() => {
    onStart();
    return () => {
      onLoaded();
    };
  }, []);

  return (
    <div className={styles.root}>
      <iframe
        width="100%"
        height="100%"
        src={
          trailer +
          "?enablejsapi=0&html5=1&wmode=transparent&modestbranding=1&iv_load_policy=3&autoplay=1&controls=0&playsinline=1&rel=0&showinfo=1&mute=1&origin=" +
          window.location.origin +
          "&widget_referrer=" +
          window.location.origin +
          "/&widgetid=1"
        }
        onLoad={() => onLoaded()}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </div>
  );
}
