import React from "react";
import styles from "./styles.module.scss";
import Link from "next/link";
import { responsiveImage } from "helpers/cdn";
import stripLink from "helpers/stripLink";

export default function Preview({ link, thumbnail, title }) {
  return (
    <Link href={stripLink(link)}>
      <a className={styles.root} href={stripLink(link)} title={title}>
        <img
          className={styles.thumbnail}
          src={responsiveImage(thumbnail, 100, 100)}
          alt={title}
          loading="lazy"
          width="100"
          height="100"
        />
      </a>
    </Link>
  );
}
