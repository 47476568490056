import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import Preview from "../Preview";
import PostMeta from "components/common/meta/PostMeta";
import Space from "components/common/Space";
import { truncate } from "lodash/string";
import stripLink from "helpers/stripLink";
import Link from "next/link";

export default function Item({
  type,
  createdAt,
  title,
  author,
  description = "",
  link,
  thumbnail,
  trailer,
  duration,
  count,
  isSlider,
}) {
  const content = useMemo(() => {
    return truncate(description.replace(/(<([^>]+)>)/gi, ""), {
      length: 130,
      separator: /,? +/,
    });
  }, [description]);

  if (!link) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Preview
        {...{ type, link, thumbnail, trailer, count, duration, title }}
      />
      <Space height="1rem" />
      <h4 className={styles.title}>
        <Link href={stripLink(link)}>
          <a
            href={stripLink(link)}
            title={title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
      </h4>
      {!isSlider && (
        <>
          <PostMeta {...{ author, createdAt }} />
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </>
      )}
    </div>
  );
}
