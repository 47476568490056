import React from 'react';
import styles from './styles.module.scss';
import Item from './Item';
import { Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

export default function Body({ items, isSlider }) {
  return (
    <div className={styles.root}>
      <Slider>
        {items.map((item, idx) => (
          <Slide key={idx}>
            <Item {...item} isSlider={isSlider} />
          </Slide>
        ))}
      </Slider>
    </div>
  );
}
