import React from 'react';
import styles from './styles.module.scss';
import OurApps from './OurApps';
import JoinVoteSubAdv from './JoinVoteSubAdv';

export default function Applications({ displayWelcomeSection = true, sidebar }) {
  return (
    <div className={sidebar ? styles.sidebar : styles.root}>
      <OurApps sidebar={sidebar} />
      {displayWelcomeSection && <JoinVoteSubAdv />}
    </div>
  );
}
