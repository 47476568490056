import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";

import Trailer from "./Trailer";
import PlayButton from "../../../../../common/buttons/PlayButton";
import { responsiveImage } from "helpers/cdn";
import stripLink from "helpers/stripLink";
import Link from "next/link";
import PlayIcon from "../../../../../common/icons/PlayIcon";
import ImageIcon from "../../../../../common/icons/ImageIcon";
import PlayCircleIcon from "../../../../../common/icons/PlayCircleIcon";

export default function Preview({
  type,
  link,
  thumbnail,
  trailer,
  duration,
  count,
  title,
}) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const videoComponents = useMemo(() => {
    return (
      type === "video" && (
        <>
          {active && trailer && (
            <Trailer
              trailer={trailer}
              onLoaded={() => setLoading(false)}
              onStart={() => setLoading(true)}
            />
          )}
          <div className={styles.playBtn}>
            <PlayButton loading={loading} active={active} />
          </div>
          <span className={styles.duration}>
            <span>{duration}</span>
          </span>
        </>
      )
    );
  }, [type, trailer, duration, active, loading]);

  const playlistComponents = useMemo(() => {
    return (
      type === "playlist" && (
        <>
          <span className={styles.count}>
            <PlayCircleIcon color="currentColor" width={12} />
            <span>{count} videos</span>
          </span>
          <span className={styles.playAll}>
            <PlayIcon color="currentColor" width={12} />
            <span>play all</span>
          </span>
        </>
      )
    );
  }, [type, count]);

  const standardComponents = useMemo(() => {
    return (
      type === "standard" && (
        <>
          <span className={styles.previewBtn}>
            <ImageIcon color="currentColor" />
          </span>
          <span
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </>
      )
    );
  }, [type, title, active]);

  return (
    <>
      <Link href={stripLink(link)}>
        <a
          className={styles.root}
          href={stripLink(link)}
          title={title}
          onMouseEnter={() => {
            setActive(true);
          }}
          onMouseLeave={() => {
            setActive(false);
          }}
        >
          <img
            className={styles.thumbnail}
            width="100%"
            height="auto"
            src={thumbnail}
            srcSet={`${responsiveImage(thumbnail, 260)} 260w, ${responsiveImage(
              thumbnail,
              360
            )} 360w, ${responsiveImage(thumbnail, 460)} 460w`}
            sizes="(max-width: 320px) 260px, (max-width: 480px) 360px, 460px"
            alt={title}
            loading="lazy"
          />
          {videoComponents}
          {playlistComponents}
          {standardComponents}
        </a>
      </Link>
    </>
  );
}
