import React from "react";
import Link from "next/link";
import { Button } from "react-bootstrap";

import style from "./style.module.scss";

export function UGCBlock({ block }) {
  const {
    image = "",
    alt = "UGC image",
    buttonText = "",
    textContent = "",
    customHtml = "",
  } = block?.data;

  return (
    <div className="text-center">
      {textContent && (
        <div
          dangerouslySetInnerHTML={{ __html: textContent }}
          className="text-center mb-3"
        />
      )}
      {customHtml && (
        <div
          className={style.root}
          dangerouslySetInnerHTML={{ __html: customHtml }}
        />
      )}
      {image && (
        <Link href="/ugc">
          <a href="/ugc" className="mb-3" style={{ display: "block" }}>
            <img
              width="100%"
              height="auto"
              src={image}
              alt={alt}
              loading="lazy"
            />
          </a>
        </Link>
      )}
      {buttonText && (
        <div className="text-center">
          <Link href="/ugc">
            <Button href="/ugc" size="sm" className="viewAll">
              {buttonText}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
