import React from "react";
import { ButtonBack, ButtonNext } from "pure-react-carousel";

import styles from "./styles.module.scss";
import ChevronRightIcon from "../../../../common/icons/ChevronRightIcon";

export default function Header({ title }) {
  return (
    <div className={styles.root}>
      <h3 className="fs-4 mb-0">{title}</h3>
      <div className={styles.btnWrap}>
        <ButtonBack className={styles.control}>
          <ChevronRightIcon width={11} rotated />
        </ButtonBack>
        <ButtonNext className={styles.control}>
          <ChevronRightIcon width={11} />
        </ButtonNext>
      </div>
    </div>
  );
}
