import { useWidgetForm } from '../../../../hooks/useWidgetForm';
import { useScript } from '../../../../hooks/useScript';
import { useFormio } from '../../../../hooks/useFormio';
import React from 'react';

export default function FormBlock({ block }) {
  const { id, content, formData } = useWidgetForm(block);
  const { status } = useScript('https://cdn.form.io/formiojs/formio.full.min.js');
  useFormio(status, id, formData);

  return <div id={id} dangerouslySetInnerHTML={{ __html: content }} />;
}
