import React, { useState } from "react";
import { CarouselProvider } from "pure-react-carousel";
import Header from "../SliderBlock/Header";
import Body from "../SliderBlock/Body";
import Indicators from "../SliderBlock/Indicators";

export function SingleSlider({ title, data: { items }, isSlider = false }) {
  const [isPlaying, setIsPlaying] = useState(true);

  return (
    items.length > 0 && (
      <React.Fragment>
        <CarouselProvider
          visibleSlides={1}
          step={1}
          isPlaying={isPlaying}
          interval={4000}
          totalSlides={items?.length}
          naturalSlideWidth={16}
          isIntrinsicHeight={9}
          className="w-100"
          onMouseEnter={() => setIsPlaying(false)}
          onMouseLeave={() => setIsPlaying(true)}
        >
          <Header title={title} />
          <Body items={items} isSlider={isSlider} />
          <Indicators items={items} />
        </CarouselProvider>
      </React.Fragment>
    )
  );
}
